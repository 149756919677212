import React from 'react';
import homepageVideo from '../videos/homepage.mp4'; // Import the video directly

const VideoSection = ({ height = '600px', className = '' }) => {
  return (
    <div className={`w-full h-auto ${className}`}>
      <video
        className="w-full object-cover"
        style={{ height: height }} // Apply dynamic height via inline styles
        autoPlay
        loop
        playsInline
      >
        <source src={homepageVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoSection;
